import api from './api';
import authHeader from './auth-header'; 

export const get_file = (path, type = "response") => {   
    return api
        .get(`file?path=${path}&type=${type}`, { headers : authHeader(), responseType: 'arraybuffer'})
        .then(response => {  
            return response;
        })
        .catch(error  => {            
            return Promise.reject(error)
        });     
}
