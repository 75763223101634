<template>
    <div>
        <Header />
        <div class="container-fluid" v-if="data.length > 0">
            <div class="wrapper-box wrapper-box-keyword" style="min-height: 583px;">
                <div class="row">
                    <div class="col-lg-12 col-md-12 col-12"><h1>My documents</h1></div>                                    
                    <template v-for="(folder,key) in documentation" :key="key">                                          
                        <div class="col-lg-12 col-md-12 col-12" >
                            <div class="box-white-rec">
                                <div class="box-level">
                                    <span :class="'list-title '+ (status[key].open ? 'open-sub' : '')" @click="status[key].open = !status[key].open"></span>
                                    <p>{{folder['name']}}<span>({{folder['count']}})</span></p>
                                    <template v-if="folder['subfolder']">
                                        <div v-for="(sub,k) in folder['subfolder']" :class="'sub-rectangle '+ (status[key].open ? 'open' : '')" :key="k">
                                            <span :class="'list-title '+ (status[key][k].open ? 'open-sub' : '')"  @click="status[key][k].open = !status[key][k].open"></span>
                                            <p>{{sub['name']}} <span>({{sub['count']}})</span></p>
                                            <div v-for="(file,id) in sub['files']" :class="status[key][k].open ? 'file open' : 'file'" :key="id">
                                                <LinkUpload :href="'/uploads/UserDocument/'+id+'/'+file['path']" @befor_request="update_document" :data_id="id" class="link-name" style="cursor:pointer">
                                                    {{file['files_name']}} <span v-if="file['is_new']" style="color: red"> (New)</span>
                                                </LinkUpload>
                                                <span :class="'icon-file '+file_icon(file['document'])"></span>
                                            </div>                                      
                                        </div>
                                    </template>                                    
                                    <template v-if="folder['files']">
                                        <div v-for="(file,id) in folder['files']"  :class="status[key].open ? 'file open' : 'file'" :key="id">
                                            <LinkUpload :href="'/uploads/UserDocument/'+id+'/'+file['path']" @befor_request="update_document" :data_id="id" class="link-name" style="cursor:pointer">
                                                {{file['files_name']}} <span v-if="file['is_new']" style="color: red"> (New)</span>
                                            </LinkUpload>
                                            <span :class="'icon-file '+file_icon(file['document'])"></span>
                                        </div>   
                                    </template>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <div class="container-fluid" v-else>
            <div class="wrapper-box wrapper-box-keyword" style="min-height: 583px;">
                <div class="row">                    
                    <div class="box-white-rec" style="padding-bottom:0; height: 150px;">             
                            <p style="text-align: center;padding-top: 58px;">{{$t('No documents available')}}</p>           
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        <Loader :isLoad="isLoad" />
    </div>    
</template>
<script>
import LinkUpload from '../components/detail/LinkUpload.vue'
import Header from "@/components/Layout/Header.vue";
import Footer from "@/components/Layout/Footer.vue";
import Loader from "@/components/Layout/Loader";

export default {
    name : 'userdocument',
    components:{
        Header,
        Footer,
        Loader,
        LinkUpload
    },
    data() {
        return {
            status : {} ,
            documentation : {}
        }
    },
    computed:{         
        data(){
            return this.$store.getters['users/data_user_document']
        },
        isLoad() {
            return this.$store.getters["users/load"];
        },        
    },
    mounted() {
        this.$store.dispatch("users/userDocument");  
    },
    methods:{      
        update_document(params = {}){
            this.$store.dispatch("users/userUpdateDocument", {id: params.data_id})
        },
        file_icon(param){            
            let icon = 'icon-pdf'  
            // console.log(matches)
            if ( /presentation/i.exec(param)){
                icon = 'icon-ppt'
            }else if (/document/i.exec(param)){
                icon = 'icon-word'                
            }
            return icon
            
        },    
        documents(param){           
            // console.log('length...',param.length)
            let arr_doc = {}       
           
            for (let k in param){                
                if( !arr_doc[param[k]['folder']]  ){
                    arr_doc[param[k]['folder']] = {}  
                }
                if ( !arr_doc[param[k]['folder']][param[k]['subfolder']]){
                    arr_doc[param[k]['folder']][param[k]['subfolder']] = {}
                }               
                arr_doc[param[k]['folder']][param[k]['subfolder']][param[k]['id']] = param[k]           
                
            }            
                        
            let new_doc = {}
            let folder=0
            let sub =0 
            let cntfolder=0
            let cntsub=0
            let stt_folder= {} 
            for (let key in arr_doc){  
                sub = 0          
                cntfolder = 0     
                stt_folder[folder]={}
                stt_folder[folder]['open']= false

                new_doc[folder]={}
                new_doc[folder]['name'] = key
                new_doc[folder]['count'] = 0     
                new_doc[folder]['subfolder'] = {}           
                for (let k in arr_doc[key]){
                    cntsub =0
                    stt_folder[folder][sub]={}
                    stt_folder[folder][sub]['open']= false

                    cntsub = Object.keys(arr_doc[key][k]).length
                    
                    if (k == 'null' || k == ''){
                        new_doc[folder]['files'] = {}
                        new_doc[folder]['files'] = arr_doc[key][k]

                    }else {                        
                        new_doc[folder]['subfolder'][sub] = {}
                        new_doc[folder]['subfolder'][sub]['name'] = k
                        new_doc[folder]['subfolder'][sub]['files'] = arr_doc[key][k]  
                        new_doc[folder]['subfolder'][sub]['count'] =  cntsub
                        sub++
                    }
                    cntfolder += cntsub                    
                    
                }            
                new_doc[folder]['count'] = cntfolder
               
                folder++
            }           
            console.log('new_doc',new_doc) 

            this.status = stt_folder
            // this.documentation = new_doc
            return new_doc
        },
       

    } ,
    watch : {
        data(){
            if (this.data){
                console.log('watch....',this.data)
                this.documentation = this.documents(this.data)
            }
        }
    }
}
</script>
