<template>
    <a style="cursor: pointer" :class="(className ? className : '')+isLoad" :rel="rel" :style="style" @click="onClickLink">
        <slot/>
    </a>
</template>

<script>
import {get_file} from '../../services/file.service';
import { ref, computed } from 'vue'
export default {
    name : "LinkUpload",
    props:{
        target: String,
        class: String,
        rel: String,
        href: String,
        style: String,
        data_id: String,
        newtab: Boolean
    },
    emits: ['befor_request'],
    setup(props, { emit }) {

        const isLoad = ref("")       

        const onClickLink = () => {    
            console.log(props.newtab);
            emit('befor_request', props);

            isLoad.value = " isLinkDisabled";
            const linkDownload = props.href;
            const filename = linkDownload.split("/").pop();


            get_file(linkDownload, "response").then(
                res => {
                    // console.log(res);
                    // console.log(res.data);
                    //console.log(res.headers['content-length']);
                    // console.log(res.headers['content-type']);
                    if(res.headers['content-length'] == 51){
                        isLoad.value = "";
                        alert("Error, The specified blob does not exist");
                        return false;
                    }
                    const contentType = res.headers['content-type'];                    
                    const blob = new Blob([res.data], {type: contentType});
                    const objectFile = window.URL.createObjectURL(blob);    
                    if(props.newtab){                        
                        window.open(objectFile, "_blank" );
                    }else{
                        const link = document.createElement('a');
                        link.href = objectFile;
                        link.setAttribute('download', filename); //or any other extension
                        document.body.appendChild(link);
                        link.click();
                    }
                    
                    isLoad.value = "";
                
                
                 
                    //If open the URL on new Window
                    //window.open(link.href, "_blank" );


                    // let image64 = Buffer.from(res.data, 'binary').toString('base64')
                    // var outputImg = document.createElement('img');
                    // outputImg.src = `data:${res.headers['content-type'].toLowerCase()};base64,${image64}`;
                    // document.body.innerHTML = "";
                    
                    
                },
                error => {
                    return Promise.reject(error);
                }
            );
        }

        const className = computed(() => props.class )


        return { onClickLink, isLoad, className }
    }
}
</script>

<style>
.isLinkDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
  pointer-events: none;
}
</style>